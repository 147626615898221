import React, { ChangeEvent, useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import { InputText, InputPassword } from '~/components/NovosInputs';
import InputSelect from '~/components/Inputs/InputSelect';
import { BtnIcon, FieldIcon } from './styles';

import { useUsuario } from '../../UsuarioContext';
import { Acesso, Select } from '../../protocols';
import { toast } from 'react-toastify';
import { timezones } from '../../data';

import ToggleDefault from '~/components/ToggleDefault';
import { getUserData } from '~/services/user';
import apiUsuario from '../../services';

import { MdEmail } from 'react-icons/md';
import PasswordChecklist from 'react-password-checklist';

export const Form: React.FC = () => {
  const {
    formUsuario,
    handleChangeFormUsuario,
    isUpdate,
    emailValidado,
    sendEmailValidateUser,
    setValue,
    register,
    errors,
    clearErrors,
    control,
    watch,
    validPass,
    handleValidPass,
  } = useUsuario();

  const [perfis, setPerfis] = useState<Select[]>([]);
  const { flg_superadmin } = getUserData();

  const getPerfis = async () => {
    const result = await apiUsuario.getAcessos();

    const { success, data, message } = result;

    if (!success) throw new Error(message);

    const options: Select[] = data.map((item: Acesso) => ({
      label: item.des_controle,
      value: item.cod_controle,
    }));

    options.sort(function (a: Select, b: Select) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setPerfis(options);
  };

  useEffect(() => {
    getPerfis();
  }, []);

  const onSendMail = async () => {
    const sendMail = await sendEmailValidateUser();
    const { success, message } = sendMail;

    if (!success) return;

    return toast.success(message);
  };

  const handleInputChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrors('email');
    const { value } = event.target;
    setValue('email', value);
  };

  return (
    <>
      <Row>
        <Col sm={12} md={5}>
          <InputText
            label="Nome *"
            placeholder="Insira o nome"
            name="des_usuario"
            register={register}
            control={control}
            caseInput="upper"
            maxLength={30}
            isError={!!errors.des_usuario}
            onInput={(ev: ChangeEvent<HTMLInputElement>) => {
              clearErrors('des_usuario');

              const { value } = ev.target;

              const regex = /^[a-zA-ZçÇáéíóúâêîôûàèìòùãõ\s]+$/;

              if (regex.test(value)) setValue('des_usuario', value);
              else setValue('des_usuario', value.replace(/[^a-zA-Z\s]/g, ''));
            }}
          />
        </Col>
        <Col sm={12} md={4} className="mr-0 pr-0">
          <FieldIcon>
            <InputText
              label="E-mail *"
              placeholder="Insira o e-mail"
              toLowerCase
              isDisabled={emailValidado}
              name="email"
              control={control}
              maxLength={100}
              onChange={handleInputChangeEmail}
              isError={!!errors.email}
              register={register}
            />

            <BtnIcon
              disabled={!isUpdate || emailValidado}
              type="button"
              onClick={onSendMail}
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                marginTop: `${emailValidado ? '1.358rem' : '1.375rem'}`,
                background: '#2B78E4',
              }}
            >
              <MdEmail size={18} />
            </BtnIcon>
          </FieldIcon>
        </Col>
        <Col sm={12} md={3}>
          <InputPassword
            label="Senha *"
            placeholder="Insira a senha"
            name="password"
            register={register}
            maxLength={100}
            isError={!!errors.password}
          />

          <PasswordChecklist
            rules={['minLength', 'specialChar', 'capital']}
            minLength={6}
            value={watch('password') ?? ''}
            messages={{
              minLength: 'Deve ter pelo menos 6 caracteres.',
              specialChar: 'Deve ter pelo menos 1 caractere especial.',
              capital: 'Deve ter pelo menos 1 letra maiúscula.',
            }}
            iconSize={13}
            style={{
              fontSize: '0.9375rem',
            }}
            onChange={(isValid) => {
              handleValidPass(isValid);
            }}
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col sm={12} md={3}>
          <InputSelect
            label="Fuso Horário *"
            noOptionsMessage="Nenhum registro encontrado"
            isRequired={!!errors.timezone}
            setInvalid={!!errors.timezone}
            iniInicializado={!!errors.timezone}
            value={
              timezones.find((item: Select) => {
                return item.value === formUsuario.timezone.value.value;
              }) ?? {
                value: undefined,
                label: 'Selecione...',
              }
            }
            options={timezones}
            onChange={(value: Select) => {
              setValue('timezone', value);
              handleChangeFormUsuario({
                ...formUsuario,
                timezone: {
                  ...formUsuario.timezone,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputSelect
            label="Controle de Acesso *"
            noOptionsMessage="Nenhum registro encontrado"
            isDisabled={
              formUsuario.flg_superadmin.value || formUsuario.flg_admin.value
            }
            value={
              perfis.find((item: Select) => {
                return item.value === formUsuario.cod_controle.value.value;
              }) ?? {
                value: undefined,
                label: 'Selecione...',
              }
            }
            options={perfis}
            isRequired={!!errors.cod_controle}
            setInvalid={!!errors.cod_controle}
            iniInicializado={!!errors.cod_controle}
            onChange={(value: Select) => {
              setValue('cod_controle', value);
              handleChangeFormUsuario({
                ...formUsuario,
                cod_controle: {
                  ...formUsuario.cod_controle,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </Col>
        {isUpdate && (
          <Col sm={12} md={2} className="mt-4">
            <ToggleDefault
              labelText="Inativo?"
              setChecked={formUsuario.flg_inativo.value}
              onSwitch={() => {
                setValue('flg_inativo', !formUsuario.flg_inativo.value);
                handleChangeFormUsuario({
                  ...formUsuario,
                  flg_inativo: {
                    ...formUsuario.flg_inativo,
                    value: !formUsuario.flg_inativo.value,
                  },
                });
              }}
            />
          </Col>
        )}
        {flg_superadmin && (
          <>
            <Col sm={12} md={2} className="mt-4">
              <ToggleDefault
                labelText="Administrador?"
                setChecked={formUsuario.flg_admin.value}
                onSwitch={() => {
                  if (!formUsuario.flg_admin.value) {
                    setValue('flg_admin', true);
                    setValue('cod_controle', { value: undefined, label: '' });
                    handleChangeFormUsuario({
                      ...formUsuario,
                      flg_admin: {
                        ...formUsuario.flg_admin,
                        value: true,
                      },
                      cod_controle: {
                        value: { value: undefined, label: '' },
                        isInvalid: false,
                        isRequired: false,
                      },
                    });
                  } else {
                    setValue('flg_admin', false);
                    setValue('flg_superadmin', false);
                    setValue('cod_controle', formUsuario.cod_controle.value);
                    handleChangeFormUsuario({
                      ...formUsuario,
                      flg_admin: {
                        ...formUsuario.flg_admin,
                        value: false,
                      },
                      flg_superadmin: {
                        ...formUsuario.flg_superadmin,
                        value: false,
                      },
                      cod_controle: {
                        ...formUsuario.cod_controle,
                        isInvalid: true,
                        isRequired: true,
                      },
                    });
                  }
                }}
              />
            </Col>
            <Col sm={12} md={2} className="mt-4">
              <ToggleDefault
                labelText="Super Admin?"
                setChecked={formUsuario.flg_superadmin.value}
                onSwitch={() => {
                  if (!formUsuario.flg_superadmin.value) {
                    setValue('flg_superadmin', true);
                    setValue('flg_admin', true);
                    setValue('cod_controle', false);
                    handleChangeFormUsuario({
                      ...formUsuario,
                      flg_superadmin: {
                        ...formUsuario.flg_superadmin,
                        value: true,
                      },
                      flg_admin: {
                        ...formUsuario.flg_admin,
                        value: true,
                      },
                      cod_controle: {
                        value: { value: undefined, label: '' },
                        isInvalid: false,
                        isRequired: false,
                      },
                    });
                    setValue('cod_controle', { value: undefined, label: '' });
                  } else {
                    setValue(
                      'flg_superadmin',
                      !formUsuario.flg_superadmin.value,
                    );
                    setValue('cod_controle', formUsuario.cod_controle.value);
                    handleChangeFormUsuario({
                      ...formUsuario,
                      flg_superadmin: {
                        ...formUsuario.flg_superadmin,
                        value: !formUsuario.flg_superadmin.value,
                      },
                      cod_controle: {
                        ...formUsuario.cod_controle,
                        isInvalid: true,
                        isRequired: true,
                      },
                    });
                  }
                }}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
