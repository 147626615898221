import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { toast } from 'react-toastify';
import { validaEmail } from '~/utils/functions';
import {
  useForm,
  FieldValues,
  UseFormRegister,
  FieldErrorsImpl,
  Control,
  UseFormSetValue,
  UseFormClearErrors,
  UseFormWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';

import { FormUsuario, Loja, Response } from './protocols';
import apiUsuario from './services';
import useAuth from '~/hooks/useAuth';

type UsuarioContextData = {
  handleChangeFormUsuario: (form: FormUsuario) => void;
  formUsuario: FormUsuario;
  handleChangeIsUpdate: (value: boolean) => void;
  handleChangeIsLoading: (value: boolean) => void;
  isUpdate: boolean;
  loader: boolean;
  onChangeInit: (value: boolean) => void;
  init: boolean;
  codUsuario: any;
  setCodUsuario: any;
  handleLojas: (value: Loja[]) => void;
  lojas: Loja[];
  handleClearFormUsuario: () => void;
  getUsuario: (codUsuario: number) => void;
  emailValidado: boolean;
  formDisabled: boolean;
  handleFormDisabled: (value: boolean) => void;
  sendEmailValidateUser: () => Promise<Response>;
  register: UseFormRegister<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: FieldErrorsImpl<{ [x: string]: any }>;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  handleSubmit: any;
  reset: any;
  getValues: any;
  handleValidPass: (valid: boolean) => void;
  validPass: boolean;
};

export const UsuarioContext = createContext({} as UsuarioContextData);

interface UsuarioContextProviderProps {
  children: ReactNode;
}

export function UsuarioContextProvider({
  children,
}: UsuarioContextProviderProps): JSX.Element {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const auth = useAuth();

  const formUsuarioBlank: FormUsuario = {
    cod_usuario: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    cod_pessoa: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    timezone: {
      value: { value: '', label: '' },
      isInvalid: true,
      isRequired: true,
    },
    cod_controle: {
      value: { value: undefined, label: '' },
      isInvalid: true,
      isRequired: true,
    },
    flg_inativo: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    flg_admin: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    flg_superadmin: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    lojas: {
      lojas: [],
      isInvalid: false,
      isRequired: false,
    },
  };

  const [formUsuario, setFormUsuario] = useState<FormUsuario>(formUsuarioBlank);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const [lojas, setLojas] = useState<Loja[]>([]);
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const [emailValidado, setEmailValidado] = useState<boolean>(false);
  const [codUsuario, setCodUsuario] = useState(undefined);
  const [validPass, setValidPass] = useState(false);

  const handleChangeFormUsuario = useCallback(
    (form: FormUsuario) => {
      setFormUsuario(form);
      setValue(
        'acesso_required',
        form.flg_superadmin.value || form.flg_admin.value,
      );
    },
    [setValue],
  );

  const handleValidPass = useCallback((valid: boolean) => {
    setValidPass(valid);
  }, []);

  const handleChangeIsUpdate = useCallback(
    (value: boolean) => {
      setValue('isUpdate', value);
      setIsUpdate(value);
    },
    [setValue],
  );

  const handleChangeIsLoading = useCallback((value: boolean) => {
    setLoader(value);
  }, []);

  const onChangeInit = (value: boolean) => {
    setInit(value);
  };

  const handleFormDisabled = (value: boolean) => {
    setFormDisabled(value);
  };

  useEffect(() => {
    getLojas();
  }, [auth.user.id]);

  const serializedData = (lojaData: Loja[]): Loja[] => {
    if (lojaData === undefined) {
      return [];
    }
    const serialized = lojaData.map((loja: Loja) => {
      loja.id = loja.cod_loja;
      loja.flg_acesso = false;
      loja.flg_padrao = false;
      loja.flg_lib_financeiro = false;
      loja.flg_vendedor = false;
      loja.selectable = true;
      loja.loja = `${loja.cod_loja} - ${loja.des_loja} (${loja.des_cidade} - ${loja.des_uf})`;
      return loja;
    });
    return serialized;
  };

  const getLojas = async () => {
    const result = await apiUsuario.getLojas(auth.user.id);
    const { data } = result;
    const resultSerialized = serializedData(data);

    setLojas(resultSerialized);
  };

  const handleClearFormUsuario = () => {
    setFormUsuario(formUsuarioBlank);
    reset();
    getLojas();
    setEmailValidado(false);
    setValue('email', '');
    setValue('des_usuario', '');
  };

  const handleLojas = (value: Loja[]) => {
    setLojas(value);
  };

  const getUsuario = async (codUser: number) => {
    const result = await apiUsuario.getUsuario(codUser);

    const { data } = result;
    const usuario = data;

    setEmailValidado(data.flg_validado);

    const codLojas: number[] = [];

    const options = usuario.lojas.map((userLoja: any) => {
      codLojas.push(userLoja.cod_loja);
      return {
        id: userLoja.cod_loja,
        cod_loja: userLoja.cod_loja,
        des_loja: userLoja.loja.des_loja,
        loja: `${userLoja.loja.cod_loja} - ${userLoja.loja.des_loja} (${userLoja.loja.des_cidade} - ${userLoja.loja.des_uf})`,
        flg_acesso: true,
        flg_padrao: usuario.cod_loja === userLoja.loja.cod_loja,
        flg_lib_financeiro: userLoja.flg_lib_financeiro,
        flg_vendedor: userLoja.flg_vendedor,
        selectable: !!lojas.find(
          (loja: Loja) => loja.cod_loja === userLoja.cod_loja,
        ),
      };
    });

    const resultAcessos = await apiUsuario.getAcessos();

    if (resultAcessos.success) {
      const controleAcesso = resultAcessos.data.filter(
        (acesso: any) => usuario.cod_controle === acesso.cod_controle,
      );

      if (controleAcesso.length > 0) {
        setValue('cod_controle', {
          value: usuario.cod_controle,
          label: controleAcesso[0].des_controle,
        });
      } else {
        setValue('cod_controle', { value: undefined, label: '' });
      }
    }

    setValue('des_usuario', usuario.des_usuario);
    setValue('email', String(usuario.email).toLowerCase());
    setValue('timezone', {
      label: usuario.timezone,
      value: usuario.timezone,
    });
    setValue('flg_inativo', usuario.flg_inativo);
    setValue('flg_admin', usuario.flg_admin);
    setValue('flg_superadmin', usuario.flg_superadmin);

    setValue('acesso_required', usuario.flg_superadmin || usuario.flg_admin);
    setValue('lojas', options);

    handleChangeFormUsuario({
      ...formUsuario,
      cod_usuario: {
        ...formUsuario.cod_usuario,
        value: usuario.id,
        isInvalid: false,
      },
      cod_controle: {
        value: { value: usuario.cod_controle, label: '' },
        isInvalid: usuario.cod_controle === null,
        isRequired: !usuario.flg_admin,
      },
      timezone: {
        ...formUsuario.timezone,
        value: { label: '', value: usuario.timezone },
        isInvalid: false,
      },
      flg_inativo: {
        ...formUsuario.flg_inativo,
        value: usuario.flg_inativo,
      },
      flg_admin: {
        ...formUsuario.flg_admin,
        value: usuario.flg_admin,
      },
      flg_superadmin: {
        ...formUsuario.flg_superadmin,
        value: usuario.flg_superadmin,
      },
      lojas: {
        ...formUsuario.lojas,
        lojas: options,
      },
    });

    const filteredLojas = lojas.filter((loja: Loja) => {
      return !codLojas.includes(loja.cod_loja);
    });

    const sortedLojas = [...options, ...filteredLojas].sort((a, b) => {
      if (a.cod_loja > b.cod_loja) return 1;
      if (a.cod_loja < b.cod_loja) return -1;
      return 0;
    });

    handleLojas(sortedLojas);
  };

  const sendEmailValidateUser = async (): Promise<Response> => {
    const email = getValues('email');

    const emailIsValid = validaEmail(email);

    if (!emailIsValid) toast.warning('O Email informado é inválido');

    const result = await apiUsuario.sendEmail(email);

    const { success, message } = result;

    if (!success) toast.warn(message);

    return {
      success,
      message,
    };
  };

  return (
    <UsuarioContext.Provider
      value={{
        handleChangeFormUsuario,
        formUsuario,
        setCodUsuario,
        codUsuario,
        handleChangeIsUpdate,
        handleChangeIsLoading,
        loader,
        isUpdate,
        handleClearFormUsuario,
        init,
        onChangeInit,
        handleLojas,
        lojas,
        getUsuario,
        emailValidado,
        formDisabled,
        handleFormDisabled,
        sendEmailValidateUser,
        register,
        getValues,
        handleSubmit,
        control,
        reset,
        setValue,
        errors,
        clearErrors,
        watch,
        validPass,
        handleValidPass,
      }}
    >
      {children}
    </UsuarioContext.Provider>
  );
}

export const useUsuario = (): UsuarioContextData => {
  return useContext(UsuarioContext);
};
